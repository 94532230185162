import { useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { Box, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { ZevBillingPieChart } from "./ZevBillingPieChart"

import { ZevBillingsChartLegend } from "./ZevBillingsChartLegend"
import { BarChartComponent } from "../analiyse/charts/BarChart.Component"
import { getBarChartColors } from "../analiyse/charts/chart.config"
import { getMeterReadingForUtilityUnit } from "../../../../domain/portal/manager/utilityUnit/UtilityUnit.Repository"
import {
  BillDetails,
  EnergyType,
  MeterReadingsCalcResolution,
  ZevPricePackage,
} from "../../../../data/generated-sources/openapi"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { AxiosErrorDataType, isAdminPortal } from "../../../Shared.Utils"
import { getZevAnaliseByUtilityUnitId } from "../../../../domain/portal/admin/utility-units/UtilityUnits.Repository"
import { ElectricityPricePackageView } from "../electricityPrices/ElectricityPricePackageView"
import { mapZevPricePackage } from "./ZevBillingPricePackageMapper"

interface ZevUnitConsumptionChartProps extends BillDetails {
  pricePackages?: ZevPricePackage[]
}

export const ZevUnitConsumptionChart = ({
  utilityUnitId,
  from,
  to,
  energyConsumption,
  billingCalculations,
  pricePackages,
}: ZevUnitConsumptionChartProps) => {
  const { t } = useTranslation()
  const [error, setError] = useState<AxiosErrorDataType>()

  const {
    data: meterReadings,
    isFetching: isFetchingReadings,
    remove: removeReading,
    refetch: refetchReadings,
  } = useQuery(
    ["getMeterData"],
    () => {
      setError(undefined)
      return isAdminPortal()
        ? getZevAnaliseByUtilityUnitId(utilityUnitId, from, to, true, MeterReadingsCalcResolution.MONTH)
        : getMeterReadingForUtilityUnit(utilityUnitId, from, to, true, MeterReadingsCalcResolution.MONTH)
    },
    {
      enabled: !!utilityUnitId,
      onError: setError,
    },
  )

  useEffect(() => {
    return () => {
      removeReading()
    }
  }, [])

  useEffect(() => {
    refetchReadings()
  }, [energyConsumption])

  const ConsumptionByEnergyType = new Map<EnergyType, { consumption: string }>()
  energyConsumption?.map(({ energyType, consumption }) => {
    ConsumptionByEnergyType.set(energyType, { consumption })
  })

  const totalConsumedData = {
    totalEnergyHighTariff: ConsumptionByEnergyType.get(EnergyType.HIGH_TARIFF)?.consumption || "",
    totalEnergyLowTariff: ConsumptionByEnergyType.get(EnergyType.LOW_TARIFF)?.consumption || "",
    totalEnergyProduction: ConsumptionByEnergyType.get(EnergyType.SOLAR)?.consumption || "",
  }

  return (
    <>
      <ErrorAlert
        scrollOnDisplay
        visible={!!error}
        message={t(`error-codes:${error?.response?.data?.code ?? error?.code ?? "OTHER"}`)}
      />
      <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"flex-start"} pt={2} flexWrap={"wrap"}>
        <ZevBillingPieChart {...totalConsumedData} />
        <Box sx={{ width: { md: "100%", lg: "60%", xl: "40%" } }} mr={6}>
          <BarChartComponent
            isLoading={isFetchingReadings}
            analiseData={meterReadings?.zev}
            barColors={getBarChartColors("ELECTRICITY", t)}
            meterReadingDateRange={MeterReadingsCalcResolution.YEAR}
          />
        </Box>
        <Box>
          <ElectricityPricePackageView
            reduceMargin
            hideEditBtn
            onSetEditMode={() => undefined}
            pricePackage={mapZevPricePackage(billingCalculations, pricePackages)}
          />
        </Box>
      </Stack>
      <ZevBillingsChartLegend {...totalConsumedData} />
    </>
  )
}
